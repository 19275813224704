import Image from 'next/image';
import { Box } from '@chakra-ui/react';
import { HeroImageProps, src } from '@/src/components/UI/HeroImageProps';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ConditionalFragment from '@/components/common/ConditionalFragment';

const Images: { [key in src]: string } = {
  Home: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/home_v_2.webp`,
  Sell: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/sell.webp`,
  aboutUS: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/about_v_2.webp`,
  jobs: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/career_v_2.webp`,
  agent: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/agent_v_3.webp`,
  abroad: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/abroad.webp`,
};
const LargeImages: Partial<{ [key in src]: string }> = {
  Home: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/home_v_2.webp`,
  aboutUS: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/about_v_2.webp`,
  abroad: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/abroad.webp`,
  agent: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/agent_v_3.webp`,
  jobs: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/career_v_2.webp`,
};
const TabletImages: Partial<{ [key in src]: string }> = {
  Home: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/tablet/hero/home_v_2.webp`,
};
const HeroImage = ({ src, alt, action, mask = false }: HeroImageProps) => {
  const [url, setUrl] = useState<string>(Images[src]);

  const isHomepage = src === 'Home';

  useEffect(() => {
    if (!window) return;
    if (!LargeImages[src]) return;
    const onResize = () => {
      let url = Images[src];

      if (window.innerWidth >= 1440) {
        url = LargeImages[src] ?? url;
      } else if (window.innerWidth >= 1024 && isHomepage) {
        url = Images[src] ?? url;
      } else if (window.innerWidth >= 768) {
        url = TabletImages[src] ?? url;
      }
      setUrl(url);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Box
      position='relative'
      className={classNames(
        // 'flex items-center justify-center',
        'w-full',
        'overflow-hidden',
        {
          'h-[36.25rem] sm:h-[30rem] md:h-[40rem]': !isHomepage,
        }
      )}
    >
      <ConditionalFragment condition={mask}>
        <div className={'relative w-full h-full bg-black z-10 bg-opacity-25'} />
      </ConditionalFragment>
      <Image
        priority
        src={url}
        className={'bg-gray300'}
        width={isHomepage ? 1024 : undefined}
        height={isHomepage ? 480 : undefined}
        objectFit={'cover'}
        layout={isHomepage ? 'responsive' : 'fill'}
        alt={alt}
      />
      {action}
    </Box>
  );
};
export default HeroImage;
